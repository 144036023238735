<script setup lang="ts">
interface Props {
  name?: string;
  disabled?: boolean;
  modelValue?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  name: '',
  disabled: false
})

const emits = defineEmits<{
  (event: 'update:modelValue', value: boolean): void;
  (event: 'change', value: boolean): void;
}>();

const handleChange = (e: Event) => {
  emits('update:modelValue', (e.target as HTMLInputElement).checked);
};
</script>

<template>
  <label
    :for="name"
    class="toggle-label"
    :class="{
      'toggle-label--selected': Boolean(modelValue),
      'toggle-label--disabled': disabled,
    }"
  >
    <div class="relative">
      <input
        type="checkbox"
        :id="name"
        :class="[
          'toggle',
          {
            'toggle--selected': Boolean(modelValue),
            'toggle--disabled': disabled,
          },
        ]"
        :value="modelValue"
        :checked="Boolean(modelValue)"
        @input="handleChange"
      />
      <span
        :class="[
          'toggle-switch',
          {
            'toggle-switch--selected': Boolean(modelValue),
            'toggle-switch--disabled': disabled,
          },
        ]"
      >
      </span>
    </div>
  </label>
</template>

<style lang="scss" scoped>
.toggle-label {
  @apply flex items-center;
  .toggle {
    @apply block w-9 h-5 cursor-pointer opacity-0;
  }
  .toggle-switch {
    @apply pointer-events-none absolute top-0 right-0 h-5 w-9 rounded-full bg-[color:var(--gray-200)] duration-300;
    &:after {
      content: '';
      @apply absolute top-[2px] left-[2px] w-4 h-4 border-[0.5px] border-gray-300 rounded-3xl bg-white duration-300;
    }
    &--selected {
      background: linear-gradient(180deg, #F36515 0%, #FDA93A 100%);
      &:after {
        @apply left-[17px];
      }
    }
  }
}
</style>
